import i18n, { use } from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getTranslations } from "./services/translationService";

/**
 * Fetch Translations from API & Add update in i18
 * @param {string} language - Language
 * @param {string} namespace - Namespace
 */
async function fetchTranslations(language, namespace) {
    const translations = await getTranslations(language, namespace);
    if (translations) {
        await i18n.addResourceBundle(language, namespace, translations?.data, true, true);
    }
}

/**
 * Fetch all DB and Enum Translations
 * @param {string} language - Language
 */
async function fetchAllTranslations(language = "en") {
    !i18n.hasResourceBundle(language, "db") && (await fetchTranslations(language, "db"));
    !i18n.hasResourceBundle(language, "enums") && (await fetchTranslations(language, "enums"));
}

// eslint-disable-next-line react-hooks/rules-of-hooks
use(Backend)
    .use(initReactI18next)
    .init({
        "cleanCode": true,
        "crossDomain": false,
        "defaultNS": "translation",
        "fallbackLng": "en",
        "interpolation": {
            "escapeValue": false // not needed for react as it escapes by default
        },
        "lng": localStorage.getItem("language") ? localStorage.getItem("language") : "en",
        "nonExplicitSupportedLngs": false,
        "ns": ["translation"],
        "react": {
            "useSuspense": false
        }
    });

i18n.on("languageChanged", async (language) => {
    await fetchAllTranslations(language);
});

export default i18n;
