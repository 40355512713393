import PropTypes from "prop-types";

/**
 * Toast component for download apis response which provide in string format
 * @param {*} props - Parent props
 * @returns {Element} - element
 */
const ToastHTMLMessage = ({ message, title }) => {
    if (message instanceof Array && message.length > 1) {
        return (
            <ul className={"message-list"}>
                {message.map((item, index) => (
                    <li key={`${item.substring(0, 5)}_${index}`}>{item}</li>
                ))}
            </ul>
        );
    }

    if (title) {
        return (
            <div>
                <p className={"toast-title"}>{title}</p>
                <span className={"toast-message"} dangerouslySetInnerHTML={{ "__html": message }}></span>
            </div>
        );
    }

    return <span dangerouslySetInnerHTML={{ "__html": message }}></span>;
};

ToastHTMLMessage.propTypes = {
    "message": PropTypes.any.isRequired,
    "title": PropTypes.string
};

export default ToastHTMLMessage;
