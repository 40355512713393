import { jwtDecode } from "jwt-decode";
import User from "../../models/user";
import { getLocalStorage, setLocalStorage } from "../../utils/commonHelper";
import { deleteAllDatabases } from "../../utils/dbHelper";

export class BaseAuth {
    midAuthObject = null;
    /**
     * Logs in user
     * @returns {boolean} - Returns always true, if auth disabled
     */
    login() {
        // To be overridden by child class
        return true;
    }

    /**
     * Logs out user and clear credentials
     */
    logout() {
        // To be overridden by child class
        this.clearCredentials();
    }

    /**
     * Check if user is authenticated
     * @param {Function} callBack - Callback function
     */
    isAuthed(callBack) {
        // To be overridden by child class
        callBack(true);
    }

    /**
     * Check if user is authenticated in asynchronous manner
     * @returns {Promise} - Promise will resolve with boolean value of authentication status
     */
    async isAuthedAsync() {
        return new Promise((resolve, reject) => {
            this.isAuthed((isAuthed) => {
                resolve(isAuthed);
            });
        });
    }

    /**
     * Clear all credentials
     */
    clearCredentials() {
        this.deleteAllCookies();
        localStorage.clear();
        sessionStorage.clear();
        deleteAllDatabases();
    }

    /**
     * Deletes all cookies
     */
    deleteAllCookies() {
        const cookies = document.cookie.split(";");

        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    /**
     * Set api header in local storage
     * @param {object} headers - API Headers
     */
    setHeader(headers) {
        setLocalStorage("api-headers", headers, true);
    }

    /**
     * Get blank user as no auth
     * @returns {User} User
     */
    getUser() {
        const user = new User({
            "email": "test@email.com",
            "firstName": "test",
            "lastName": "user",
            "roles": ["viewer", "admin", "super_admin"],
            "tenants": ["demo", "test", "bigdata", "large-data"]
        });
        setLocalStorage("user", user, true);

        return user;
    }

    /**
     * Check if token is valid and not expired
     * @returns {boolean} true, if token valid
     */
    checkTokenValid() {
        try {
            const token = getLocalStorage("api-headers", true).authorization.split(" ")[1];
            const decodedToken = jwtDecode(token);
            const currentDate = new Date();

            // JWT exp is in seconds, valid if expiry more than current time
            if (decodedToken.exp * 1000 >= currentDate.getTime()) {
                // Valid token
                return true;
            } else {

                //TODO: comment this line after implementing refresh token

                /**
                 * Previously we were refreshing the page to regenerate the token by removing 'api-headers'
                 * from local storage. But now we are using refresh method to generate a new token without refreshing
                 * the screen and interrupting the user.
                 */

                // this.midAuthObject.refresh();
                localStorage.removeItem("api-headers"); // old approach
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Invalid token - ", e);
            localStorage.removeItem("api-headers");
            localStorage.removeItem("user");
        }

        return false;
    }

    /**
     * Sets pre session and pre login urls on load
     */
    handleRedirect = () => {
        const preAuthUrl = sessionStorage.getItem("pre-session-timeout-url");
        if (
            (
                (
                    window.location.pathname === "/" && 
                    (document.referrer.includes("auth/callback") || document.referrer.includes("/login"))
                ) ||
                window.location.pathname === "/auth/callback"
            ) &&
            preAuthUrl !== null &&
            window.location.pathname !== preAuthUrl
        ) {
            window.location.replace(preAuthUrl);
        } else {
            window.location.replace("/select-tenant");
        }
    };

    /**
     * Saves last url, so app can land back after auth
     */
    saveLastUrl = () => {
        // Save current url whenever route changes
        if (!window.location.pathname.includes("/login") && !window.location.pathname.includes("/auth/callback")) {
            sessionStorage.setItem("pre-session-timeout-url", `${window.location.pathname}${window.location.search}`);
        }
    };
}
