import { toast } from "react-toastify";
import ToastHTMLMessage from "../components/ToastHTMLMessage/ToastHTMLMessage";
import i18n from "../i18n";
import { downloadFileDirectlyFromResponse } from "../utils/commonHelper";
import axios, { streamApi } from "./axios";
import { serviceUrls } from "./serviceUrls";

/**
 * Get Configuration Value
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - Configuration Values
 */
export const getConfigValues = (config = { "handlerEnabled": true }, axiosInstance = axios) => {
    return axiosInstance.get(serviceUrls("config"), config);
};

/**
 * Update Configuration
 * @param {object} data - Configurations to update
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - Response Message
 */
export const updateConfigValues = (data, config = { "handlerEnabled": true }, axiosInstance = axios) => {
    return axiosInstance.put(serviceUrls("config"), data, config);
};

/**
 * Get Configuration Value
 * @param {object} data - Data we want to store in backend
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - Configuration Values
 */
export const postConfigValues = (data, config = { "handlerEnabled": true }, axiosInstance = axios) => {
    return axiosInstance.post(serviceUrls("binWidth"), data, config);
};

/**
 * Get Input Files List
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - Array of Input Files
 */
export const getInputFiles = (config = { "handlerEnabled": true }, axiosInstance = axios) => {
    return axiosInstance.get(serviceUrls("files"), config);
};

/**
 * Delete Input File
 * @param {object} body - File Details of file to be deleted
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - Response Message
 */
export const deleteInputFiles = (body, config = { "handlerEnabled": true }, axiosInstance = axios) => {
    return axiosInstance.delete(serviceUrls("files"), { "data": body }, config);
};

/**
 * Get File Download URL
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - File Download URL
 */
export const getFileDownloadUrl = (config = { "handlerEnabled": true }, axiosInstance = axios) => {
    if (config.responseType === "blob") {
        return streamApi(serviceUrls("files.preSignedUrl"), "GET", {}, config, true);
    } else {
        return axiosInstance.get(serviceUrls("files.preSignedUrl"), config);
    }
};

/**
 * Get file size in human readable string
 * @param {number} bytes - File size in bytes
 * @returns {string} - File size in human readable string
 */
export const humanFileSize = (bytes) => {
    const index = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${Number((bytes / Math.pow(1024, index)).toFixed(2)) * 1} ${["B", "KB", "MB", "GB", "TB"][index]}`;
};

/**
 * Async generator method to upload files in chunks
 * @param {*} formData - FormData to be uploaded with 1 file
 * @param {number} retryIndex - Retry index to keep count of retries
 * @param {string} uploadId - To upload in chunks
 * @param {object} partInfo - File parts
 * @yields {object} - Response with file upload progress info
 */
export const fileUpload = async function* (formData, retryIndex = 0, uploadId = null, partInfo = null) {
    const filesToUpload = {};
    // It must be more than minimum size for multi part upload in S3 which is 5 MB
    const chunkSize = 1024000 * 6; // 6MB

    // Calculate file size and total no. of chunks, so appropriate calls can be made
    formData.forEach((value, key) => {
        if (key.startsWith("file")) {
            filesToUpload[key] = value;
            formData.delete(key);
        }
    });

    let finalResponse;
    for (const fileKey of Object.keys(filesToUpload)) {
        const uploadFile = filesToUpload[fileKey];
        // Adding 1, so call with empty chunk is made to finish the process
        const fileChunkCount = Math.ceil(uploadFile.size / chunkSize) + 1;
        const minThresholdForTimeCalculation = 2;
        const startTime = new Date();
        let timeForEachChunk;
        let timeRemaining;

        for (let i = retryIndex; i <= fileChunkCount; i++) {
            if (i === 0) {
                formData.set("file", new Blob(), uploadFile.name);
            } else if (i < fileChunkCount) {
                // Creating chunk with -1 as mid will start from 1 but we want chunk from 0
                const fileChunk = uploadFile.slice((i - 1) * chunkSize, i * chunkSize);
                formData.set("file", fileChunk, uploadFile.name);
            } else if (i === fileChunkCount) {
                formData.set("file", new Blob(), uploadFile.name);
            }
            formData.set("chunk", i);
            formData.set("upload_id", uploadId);
            formData.set("part_info", JSON.stringify(partInfo));
            formData.set("total_chunks", fileChunkCount);

            try {
                finalResponse = await streamApi(serviceUrls("files.upload"), "POST", formData, {
                    "headers": { "Content-Type": "multipart/form-data" }
                });
                uploadId = finalResponse.data["upload_id"];
                partInfo = finalResponse.data["part_info"];
            } catch (e) {
                // Return form data in case of failure, so same call be attempted again
                if (!e.data) {
                    // Return retry index only if unknown error was generated
                    const error = { "error": e, "index": i, partInfo, "status": false, uploadId };
                    throw error;
                } else {
                    // Else pass the error received from server
                    throw e;
                }
            }

            // Calculate progress and time remaining
            if (i === minThresholdForTimeCalculation) {
                // Calculate time taken after minimum threshold
                timeForEachChunk = (new Date() - startTime) / 1000 / minThresholdForTimeCalculation;
                // Subtracting 1 as last chunk is empty
                const remainingChunks = fileChunkCount - i - 1;
                timeRemaining = Math.ceil(remainingChunks * timeForEachChunk);
            } else if (i > minThresholdForTimeCalculation) {
                // Subtracting 1 as last chunk is empty
                const remainingChunks = fileChunkCount - i - 1;
                timeRemaining = Math.ceil(remainingChunks * timeForEachChunk);
            }
            const totalFileSize = uploadFile.size;
            const totalUploaded = i >= fileChunkCount - 2 ? totalFileSize : (i + 1) * chunkSize;
            const progress = ((totalUploaded / totalFileSize) * 100).toFixed(2);
            yield {
                progress,
                timeRemaining,
                "totalSize": humanFileSize(totalFileSize),
                "totalUploaded": humanFileSize(totalUploaded)
            };
        }
    }

    yield finalResponse;
};

/**
 * Download file
 * @param {string} file - file name
 * @param {boolean} isFolder - is folder
 * @param {boolean} open - should open file in new window for single file
 * @returns {void}
 */
export const downloadFile = async (file, isFolder, open = true) => {
    const apiConfig = {
        "handlerEnabled": true,
        "params": {
            "file_name": file,
            "type": "get"
        }
    };

    if (isFolder) {
        apiConfig["responseType"] = "blob";
    }

    const response = await getFileDownloadUrl(apiConfig)
        .then((response) => {
            if (open) {
                window.open(response.response);
            }

            if (!isFolder) {
                return response.response;
            }

            return null;
        })
        .catch(() => {});

    return response;
};

/**
 * Handle response of export job calls
 * @param {object} response - API Response
 * @param {Function} addOngoingExportJob - Function to add export job to context
 */
export const handleExportJobResponse = (response, addOngoingExportJob) => {
    if (response?.success) {
        toast.success(<ToastHTMLMessage message={response.message} title={i18n.t("export_file_job_created")} />, {
            "autoClose": false,
            "toastId": `exportJob-${response.data.job_id}`
        });
        if (response?.data?.exported_file_name) {
            downloadFileDirectlyFromResponse(response);
        } else {
            addOngoingExportJob(response.data.job_id);
        }
    }
};

/**
 * Download file template
 * @param {*} queryParams - Query Params
 * @param {*} config - API Config
 * @returns {Promise} - API Promise
 */
export const downloadTemplate = (queryParams, config = { "handlerEnabled": true, "responseType": "blob" }) => {
    return streamApi(serviceUrls("system.downloadTemplate", queryParams), "GET", {}, config, true);
};

/**
 * Get Configuration Value
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - Configuration Values
 */
export const getTransformerConfig = (config = { "handlerEnabled": true }, axiosInstance = axios) => {
    return axiosInstance.get(serviceUrls("transformerConfig"), config);
};

/**
 * Get Configuration Value
 * @param {object} data - Data we want to store in backend
 * @param {object} config - API Configuration
 * @param {axios} axiosInstance - Axios Object Instance
 * @returns {Promise} - Configuration Values
 */
export const postTransformerConfig = (data, config = { "handlerEnabled": true }, axiosInstance = axios) => {
    return axiosInstance.post(serviceUrls("transformerConfig"), data, config);
};
